/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import Flag from 'react-world-flags';

import EmailFormFr from '../components/newsletter/EmailFormFr';
import logo from '../../static/logo.png';

const Layout = ({ children, maintenance }) => {
  return (
    <div className="layout" id="top">
      <Helmet>
        <script
          src="https://kit.fontawesome.com/244838bf67.js"
          crossOrigin="anonymous"
        ></script>
      </Helmet>
      {!maintenance && (
        <nav id="main" className="navbar navbar-expand-lg fixed-top navbar-dark">
          <div className="container">
            <a className="navbar-brand flex-grow-1" href="/">
              <img src={logo} alt="Cinélangues" with="120" height="120" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-center"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown1"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fal fa-film"></i> Programmation 2024-2025
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown1">
                    <a
                      className="dropdown-item"
                      href="/espagnol-les-films-cinelangues/"
                    >
                      <Flag code="es" height="12" /> &nbsp;Espagnol
                    </a>
                    <a
                      className="dropdown-item"
                      href="/allemand-les-films-cinelangues/"
                    >
                      <Flag code="de" height="12" /> &nbsp;Allemand
                    </a>
                    <a
                      className="dropdown-item"
                      href="/italien-les-films-cinelangues/"
                    >
                      <Flag code="it" height="12" /> &nbsp;Italien
                    </a>
                    {/*
                      <a className="dropdown-item" href="/festival-partenaires/">
                        <i className="fa fa-calendar" /> &nbsp;Evènements
                      </a>
                    */}
                    <a className="dropdown-item" href="/archives-cinema-latin">
                      <i className="fa fa-box"></i> &nbsp;Archives
                    </a>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown2"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fal fa-ticket"></i> Réserver
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown2">
                    <a className="dropdown-item" href="/a-propos-reserver">
                      A Paris
                    </a>
                    <a className="dropdown-item" href="/a-propos-reserver-region">
                      En Région
                    </a>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown2"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fal fa-camera-movie"></i> Mission
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown2">
                    <a className="dropdown-item" href="/a-propos-qui-sommes-nous">
                      Qui sommes-nous ?
                    </a>
                    <a
                      className="dropdown-item"
                      href="/a-propos-cinelangues-2024-2025"
                    >
                      Edito 2024-2025
                    </a>
                    <a className="dropdown-item" href="/a-propos-participer">
                      Participer
                    </a>

                    <a className="dropdown-item" href="/a-propos-partenaires">
                      Partenaires
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/fr/blog">
                    <i className="fal fa-bullhorn"></i> Actus
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/a-propos-nous-contacter">
                    <i className="fal fa-envelope"></i> Contact
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.facebook.com/cinelangues"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <i className="fa fa-facebook"></i>
                    <span className="hide">facebook</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}
      <main>{children}</main>
      {!maintenance && (
        <footer className="main">
          <div className="jumbotron newsletter">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-10 offset-lg-2 header-content my-auto">
                  <div className="row">
                    <div className="col-lg-2 d-none d-lg-block mr-3">
                      <i className="fal fa-paper-plane"></i>
                    </div>
                    <div className="col-lg-8 ">
                      <h2 className="mt-0">Recevoir la programmation</h2>
                      <EmailFormFr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav className="navbar navbar-dark bg-dark">
            <div className="container">
              <span className="navbar-text">
                copyright © {new Date().getFullYear()}
              </span>
              <span className="navbar-text">
                <small>
                  &nbsp;&nbsp;by&nbsp;
                  <a
                    href="http://www.io-digital.net"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    io-digital
                  </a>
                </small>
              </span>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" href="/terms">
                    Conditions d'utilisation
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </footer>
      )}
    </div>
  );
};

export default Layout;
