import React, { Component } from 'react';

export class EmailForm extends Component {
  render() {
    return (
      <form
        method="post"
        name="newsletter-fr"
        action="/success"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="newsletter-fr" />
        <div className="form-group">
          <div className="row">
            <input
              className="form-control form-control-lg mt-1 mr-1 col-md-7"
              type="email"
              required
              name="email"
              id="email"
              placeholder="Saisissez votre email ..."
            />
            <button className="btn btn-warning mt-1" type="submit">
              ENVOYER
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default EmailForm;
